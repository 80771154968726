
import {defineComponent, ref, reactive, toRefs,computed, onMounted} from 'vue';
import {useRouter} from 'vue-router';
import {ElMessage, ElMessageBox} from 'element-plus';
import { ElLoading } from 'element-plus';
import axios from '@/api/http.js';

export default defineComponent({
  name: 'Login',

  setup() {
    const route = useRouter(); // 第一步
    const data = reactive({
      userName: '',
      userPwd: '',
      showUserName: true,
      checkboxVal: false,
      showTip:false,//勾选提示
      tip:''
    });
    const showCheckBox = computed(() => {
      return data.showUserName;
    });
    const next = () => {
      if (data.userName == '') {
        data.tip = 'Sorry, you\'re not currently registered for this event. Please click the register here hyperlinked text to the left to register.';
        data.showTip = true
        // ElMessageBox.confirm(
        //     'Please input your Username',
        //     {
        //       confirmButtonText: 'Yes',
        //       // cancelButtonText: "Cancel",
        //       type: 'warning',
        //     }
        // );
      } else {
        if (data.checkboxVal) {
         const loading = ElLoading.service({
        lock: true,
      })
          axios.request({
            method: 'POST',
            url: 'm/check-email/',
            data: {
              email: data.userName
            }
          }).then(res => {
            console.log('res:', res);
            console.log('res.data.result:', res.data.result);
            if (res.data.result == 'succeeded') {
              data.showUserName = false;
              data.showTip = false
            }
            loading.close();
          }).catch(err => {
            loading.close();
            data.tip = err.response.data.error;
            data.showTip = true
            // ElMessageBox.confirm(
            //     err.response.data.error,
            //     {
            //       confirmButtonText: 'Yes',
            //       // cancelButtonText: "Cancel",
            //       type: 'warning',
            //     }
            // );
          });
        } else {
          data.tip = "You must accept the Terms of Use to proceed";
          data.showTip = true
          // ElMessageBox.confirm(
          //     'You must accept the Terms of Use to proceed.',
          //     'Confirmation',
          //     {
          //       confirmButtonText: 'Yes',
          //       // cancelButtonText: "Cancel",
          //       type: 'warning',
          //     }
          // );
        }
      }
    };
    const login = () => {
      if (data.userName == '') {
        data.tip = 'Please input your password';
        data.showTip = true
        // ElMessageBox.confirm(
        //     'Please input your password',
        //     {
        //       confirmButtonText: 'Yes',
        //       // cancelButtonText: "Cancel",
        //       type: 'warning',
        //     }
        // );
      }else{
         const loading = ElLoading.service({
        lock: true,
      })
        axios.request({
          method: 'POST',
          url: '/m/authenticate/',
          data: {
            username: data.userName,
            password: data.userPwd,
          }
        }).then(res => {
          loading.close();
          if (res.data.result == 'succeeded') {
            localStorage.webToken = res.data.token;
            let campusInfo = res.data;
            localStorage.campusInfo = JSON.stringify(res.data);
            // route.push({name: 'Mec'});
            route.push({name: 'Home'});
          }else{
            console.log("==== result=====",res.data.error)
            data.tip = res.data.error;
            data.showTip = true
            // ElMessageBox.confirm(
            //     res.data.error,
            //     {
            //       confirmButtonText: 'Yes',
            //       // cancelButtonText: "Cancel",
            //       type: 'warning',
            //     }
            // );
          }
        }).catch(err => {
          console.log(err);
          loading.close();
          data.tip = err.response.data.error;
          data.showTip = true;
          // ElMessageBox.confirm(
          //     err.response.data.error,
          //     {
          //       confirmButtonText: 'Yes',
          //       // cancelButtonText: "Cancel",
          //       type: 'warning',
          //     }
          // );
        })
      }
    };
    const fun = ()=>{
    }
    onMounted(() => {
      window.addEventListener("keydown",(e)=>{
        if(e.keyCode===13){
          if(data.showUserName){
            next();
          }else{
            login();
          }
        }
      })
    });
    return {
      fun,
      ...toRefs(data),
      showCheckBox,
      next,
      login,
    };
  },
});
